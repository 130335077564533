@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Inter", sans-serif;
  }
}

@layer components {
  input[type="checkbox"]:checked ~ label span svg {
    @apply inline-flex;
  }

  .sticky .header-logo {
    @apply py-5 lg:py-2;
  }

  .sticky .menu-scroll.active {
    @apply opacity-70;
  }

  input#togglePlan:checked ~ .dot {
    @apply translate-x-full;
  }

  input#checkboxLabel:checked ~ .box span {
    @apply opacity-100;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: dimgrey;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tabs button.active {
  background-color: darkgrey;
}

.logo {
  width: 175px;
  height: 175px;
  object-fit: contain;
}

.toast-container .Toastify__toast {
  background: #343a40; /* dark background */
  color: #fff; /* white text */
  font-size: 110%;
  padding: 1.20em;
  line-height: 1.20;
}
